const getAuthorBaseUrl = (path = "") => `/blogs/authors/${path}`;

const getCategoryBaseUrl = (path = "") => `/blogs/categories/${path}`;

const getTagBaseUrl = (path = "") => `/blogs/tags/${path}`;

const getBlogBaseUrl = (path = "") => `/blogs/${path}`;

module.exports = {
  getAuthorBaseUrl,
  getCategoryBaseUrl,
  getTagBaseUrl,
  getBlogBaseUrl,
};
