import Link from "../Link"
import React from "react"
import './Header.css'
import axios from 'axios'
import cookie from 'react-cookies'

import Button from '../Button/Button'
import Dropdown from '../Dropdown/Dropdown'
import RgurooList, { RgurooListSubmenu } from '../RgurooList/RgurooList'
import ContextConsumer from '../../layouts/context'

import logo from '../../images/rguroo_logo.png'

import { apiURL } from '../../Data'

import ListItem from "@mui/material/ListItem";
import Drawer from "@mui/material/Drawer";
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import MenuIcon from '@mui/icons-material/Menu'
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { ClickAwayListener, Backdrop, Collapse } from "@mui/material";
import { Alert, AlertTitle } from '@mui/lab';
import trackCustomEvent from '../../customEvent'
import { getBlogBaseUrl } from "../../helpers"

/**
 * * Header layout component
 * * State:
 * @param {Boolean} mobileMenuOpen Determines whether or not the mobile menu is open
 * @param {Int} width Width of the viewport
 */

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
      width: 0,
      version: '',
      announcements: [],
      openAnnouncements: []
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  signIn = e => {
    if (typeof window !== 'undefined') {
      trackCustomEvent({
        event: 'signin',
        action: 'click',
        category: 'button click',
        label: 'Sign In'
      })
      window.open('https://app.rguroo.com/?v=' + this.state.version, '_blank', 'noopener noreferrer');
    }
  }

  componentDidMount = () => {
    // Initialize width state and add a listener to keep updating it
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // Initialize cookies for accessibility features
    if (cookie.load('highContrast') === undefined) { }
    cookie.save('highContrast', this.props.context.highContrast);
    if (cookie.load('largeText') === undefined)
      cookie.save('largeText', this.props.context.largeText);

    axios.get(apiURL + 'registration/getProperties')
      .then(resp => {
        this.setState({ version: resp.data.main.version.split('?v=')[1], announcements: resp.data.main.announcements, openAnnouncements: resp.data.main.announcements.map(ann => ann.announcementID) });
      }).catch(err => this.setState({ version: "nocache" }));
  }

  componentWillUnmount = () => window.removeEventListener('resize', this.updateWindowDimensions);

  updateWindowDimensions = () => this.setState({ width: window.innerWidth });

  toggleMobileMenu = state => e => {
    this.setState({ mobileMenuOpen: state })
  }

  toggleMenuTargetOnly = e => {
    if (e.target !== e.currentTarget)
      return;
    this.toggleMobileMenu(false)(null);
  }

  // Updates one of the cookies associated with an accessibility toggler
  updateAccessibilityCookie = name => e => {
    if ((e.type === "keyup" && (e.key === "Space" || e.key === "Enter")) || e.type !== "keyup")
      cookie.save(name, cookie.load(name) !== 'true');
    this.props.setContext({ [name]: !this.props.context[name] })
  }

  render() {
    /**
    * * Desktop/Tablet breakpoint at 1000px
    * * Tablet/Mobile breakpoint at 600px
    */
    if (this.state.width > 1000 || this.state.width === 0)
      return this.renderDesktop();
    else
      return this.renderMobile();
  }

  renderAnnouncements = () => {
    const handleClose = id => () => this.setState({ openAnnouncements: this.state.openAnnouncements.filter(annID => annID !== id) });
    return this.state.announcements.map(({ announcementID, showOnce, severity, title, message }) => {
      if (showOnce) {
        if (cookie.load(`announcement${announcementID}`) === undefined)
          cookie.save(`announcement${announcementID}`);
        else
          return null;
      }
      let alertSeverity = "";
      switch (severity) {
        case "S":
          alertSeverity = "success";
          break;
        case "W":
          alertSeverity = "warning";
          break;
        case "I":
          alertSeverity = "info";
          break;
        case "E":
          alertSeverity = "error";
          break;
        default:
          break;
      }

      return (
        <Collapse in={this.state.openAnnouncements.includes(announcementID)}>
          <Grid container justifyContent='space-around'>
            <Alert onClose={handleClose(announcementID)} severity={alertSeverity} style={{ maxWidth: 700, margin: '10px 0' }}>
              {title && title !== "" ? <AlertTitle>{title}</AlertTitle> : null}
              {message}
            </Alert>
            <div style={{ width: "220px" }} />
          </Grid>
        </Collapse>
      );
    });
  }

  // Entirely different render method for desktop
  renderDesktop = () => (
    <header>
      <div id='accessibility-bar'>
        <Dropdown title={
          <React.Fragment>
            <AccessibilityNewIcon /> Accessibility
        </React.Fragment>} clickable uid='accessibility'>
          <ListItem><Typography tabIndex="0" role="button" onKeyUp={this.updateAccessibilityCookie('largeText')} onClick={this.updateAccessibilityCookie('largeText')}>Toggle Large Text</Typography></ListItem>
          <ListItem><Typography tabIndex="0" role="button" onKeyUp={this.updateAccessibilityCookie('highContrast')} onClick={this.updateAccessibilityCookie('highContrast')}>Toggle High Contrast</Typography></ListItem>
          <ListItem><Link to="/accessibility">More Information</Link></ListItem>
        </Dropdown>
      </div>
      {this.renderAnnouncements()}
      <nav id='navigation'>
        <ul>
          <li id='logo-wrapper'><Link to='/'><img id='logo' src={logo} alt='rguroo logo' /></Link></li>
          <li><Link to='/'><Typography className="text-blue bold">Home</Typography></Link></li>
          <li><Dropdown uid='features' title='Features'>
            <ListItem><Link to='/cloud-analytics'><Typography className="text-blue">Cloud Analytics</Typography></Link></ListItem>
            <ListItem><Link to='/teaching'><Typography className="text-blue">Teaching</Typography></Link></ListItem>
            <ListItem><Link to='/statistical-analysis'><Typography className="text-blue">Statistical Analysis</Typography></Link></ListItem>
            <ListItem><Link to='/simulation'><Typography className="text-blue">Probability and Simulation</Typography></Link></ListItem>
            <ListItem><Link to='/data-visualization'><Typography className="text-blue">Data Visualization</Typography></Link></ListItem>
            <ListItem><Link to='/data-tools'><Typography className="text-blue">Data Tools</Typography></Link></ListItem>
          </Dropdown></li>
          <li><Dropdown uid='Resources' title='Resources'>
            <ListItem><a rel="noopener noreferrer" href={'http://files.rguroo.com/guides/quick_userguide.html'} target="_blank">Quick User's Guide</a></ListItem>
            <ListItem><a rel="noopener noreferrer" href={'http://files.rguroo.com/guides/quick_userguide_dashboard.html'} target="_blank">Dashboard Quick Guide</a></ListItem>
            <ListItem><Link to='/video-tutorials'><Typography className="text-blue">Video Tutorials</Typography></Link></ListItem>
            <ListItem><Link to='/request-training'><Typography className="text-blue">Request Training/Demo</Typography></Link></ListItem>
            <ListItem><Link to='/resources'><Typography className="text-blue">Labs & Book-Related</Typography></Link></ListItem>
            <ListItem><Link to='/webinars'><Typography className="text-blue">Webinars</Typography></Link></ListItem>
            <ListItem><a rel="noopener noreferrer" href={'http://files.rguroo.com/guides/latest_userguide.pdf'} target="_blank">Full User's Guide</a></ListItem>
          </Dropdown></li>
          <li><Dropdown uid='support' title='Support'>
            <ListItem><Link to='/support'><Typography className="text-blue">Contact Support</Typography></Link></ListItem>
            <ListItem><Link to='/premium-support'><Typography className="text-blue">Premium Support</Typography></Link></ListItem>
            <ListItem><Link to='/faq'><Typography className="text-blue">FAQ</Typography></Link></ListItem>
          </Dropdown></li>
          <li><Dropdown uid='about' title='About'>
            <ListItem><Link to='/about'><Typography className="text-blue">About Us</Typography></Link></ListItem>
            <ListItem><Link to='/testimonials'><Typography className="text-blue">Testimonials</Typography></Link></ListItem>
          </Dropdown></li>
          <li><Link to='/release-notes'><Typography className="text-blue bold">Release Notes</Typography></Link></li>
          <li><Link to={getBlogBaseUrl()}><Typography className="text-blue bold">Blog</Typography></Link></li>
          <li><Link to='/pricing'><Typography className="text-blue bold">Pricing</Typography></Link></li>
        </ul>
        <ul>
          <li>
            <Button outlined padded onClick={this.signIn}><Typography className='bold' component='a'>Sign In</Typography></Button>
          </li>
          <li>
            <Button outlined padded><Link to='/register'><Typography className='text-blue bold' component='span'>Register</Typography></Link></Button>
          </li>
        </ul>
      </nav>
    </header>
  )

  // Entirely different render method for mobile/tablet
  renderMobile = () => (
    <ClickAwayListener onClickAway={this.toggleMobileMenu(false)}>
      <header>
        <div id='mobile-menu-bar'>
          <MenuIcon onClick={this.toggleMobileMenu(true)} />
          <div id='accessibility-bar'>
            <Dropdown title={
              <React.Fragment>
                <AccessibilityNewIcon /> Accessibility
              </React.Fragment>} clickable uid='accessibility'>
              <ListItem><Typography tabIndex="0" role="button" onKeyUp={this.updateAccessibilityCookie('largeText')} onClick={this.updateAccessibilityCookie('largeText')}>Toggle Large Text</Typography></ListItem>
              <ListItem><Typography tabIndex="0" role="button" onKeyUp={this.updateAccessibilityCookie('highContrast')} onClick={this.updateAccessibilityCookie('highContrast')}>Toggle High Contrast</Typography></ListItem>
              <ListItem><Link to="/accessibility">More Information</Link></ListItem>
            </Dropdown>
          </div>
        </div>
        <Backdrop open={this.state.mobileMenuOpen} onClick={this.toggleMenuTargetOnly} style={{ zIndex: "1000" }}>
          <Drawer
            variant='persistent'
            open={this.state.mobileMenuOpen}
            anchor={'left'}
            id='mobile-menu-drawer'
          >
            <RgurooList>
              <Grid container justifyContent='space-between' style={{ padding: "10px 15px 5px" }}>
                <Button outlined padded onClick={this.signIn}><Typography className='bold' component='a'>Sign In</Typography></Button>
                <Button outlined padded><Link to='/register'>Register</Link></Button>
              </Grid>
              <Link to='/'><ListItem onClick={this.toggleMobileMenu(false)}>
                <Typography variant='button' color='textSecondary'>Home</Typography>
              </ListItem></Link>
              <RgurooListSubmenu title='Features'>
                <Link to='/cloud-analytics'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Cloud Analytics</Typography>
                </ListItem></Link>
                <Link to='/teaching'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Teaching</Typography>
                </ListItem></Link>
                <Link to='/statistical-analysis'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Statistical Analysis</Typography>
                </ListItem></Link>
                <Link to='/simulation'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Probability and Simulation</Typography>
                </ListItem></Link>
                <Link to='/data-visualization'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Data Visualization</Typography>
                </ListItem></Link>
                <Link to='/data-tools'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Data Tools</Typography>
                </ListItem></Link>
              </RgurooListSubmenu>
              <RgurooListSubmenu title='Resources'>
              <a rel="noopener noreferrer" href={'http://files.rguroo.com/guides/quick_userguide.html'} target="_blank"><ListItem onClick={this.toggleMobileMenu(false)}>
                <Typography variant='button' color='textSecondary'>Quick User's Guide</Typography>
              </ListItem></a>
              <a rel="noopener noreferrer" href={'http://files.rguroo.com/guides/quick_userguide_dashboard.html'} target="_blank"><ListItem onClick={this.toggleMobileMenu(false)}>
                <Typography variant='button' color='textSecondary'>Dashboard Quick Guide</Typography>
                </ListItem></a>
                <Link to='/video-tutorials'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Video Tutorials</Typography>
                </ListItem></Link>
                <Link to='/request-training'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Request Training/Demo</Typography>
                </ListItem></Link>
                <Link to='/resources'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Labs & Book-Related</Typography>
                </ListItem></Link>
                <Link to='/webinars'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Webinars</Typography>
                </ListItem></Link>
                <a rel="noopener noreferrer" href={'http://files.rguroo.com/guides/latest_userguide.pdf'} target="_blank"><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>User's Guide</Typography>
                </ListItem></a>
                <Link to='/user-guide-archive'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>User's Guide Archive</Typography>
                </ListItem></Link>
              </RgurooListSubmenu>
              <RgurooListSubmenu title='Support'>
                <Link to='/support'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Contact Support</Typography>
                </ListItem></Link>
                <Link to='/premium-support'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Premium Support</Typography>
                </ListItem></Link>
                <Link to='/faq'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>FAQ</Typography>
                </ListItem></Link>
              </RgurooListSubmenu>
              <RgurooListSubmenu title='About'>
                <Link to='/about'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>About Us</Typography>
                </ListItem></Link>
                <Link to='/testimonials'><ListItem onClick={this.toggleMobileMenu(false)}>
                  <Typography variant='button' color='textSecondary'>Testimonials</Typography>
                </ListItem></Link>
              </RgurooListSubmenu>
              <Link to='/release-notes'><ListItem onClick={this.toggleMobileMenu(false)}>
                <Typography variant='button' color='textSecondary'>Release Notes</Typography>
              </ListItem></Link>
              <Link to={getBlogBaseUrl()}><ListItem onClick={this.toggleMobileMenu(false)}>
                <Typography variant='button' color='textSecondary'>Blog</Typography>
            </ListItem></Link>
              <Link to='/pricing'><ListItem onClick={this.toggleMobileMenu(false)}>
                <Typography variant='button' color='textSecondary'>Pricing</Typography>
              </ListItem></Link>
            </RgurooList>
          </Drawer>
        </Backdrop>
      </header>
    </ClickAwayListener>
  )
}

export default () => <ContextConsumer>{({ data, set }) => <Header setContext={set} context={data} />}</ContextConsumer>
