import { Link } from "gatsby"
import React from 'react';
import Typography from '@mui/material/Typography'

/**
 * * Wrapper over the Gatsby Link component for ease of use. Not to be used for external links
 * @param {Node} children Optional: Content 
 * @param {String} variant Optional: Typography variant 
 * @param {String} color Optional: Typography color
 * @param {String} to Required: Determines where the link navigates to
 * @param rest... Applied to Link Component
 */
const RGRLink = ({ children, variant = 'button', color = 'textSecondary', typographyStyles = {}, inline = false, noTypography = false, ...rest }) => {
  return noTypography ? <Link {...rest} style={{ display: inline ? "inline" : "initial", color: 'rgb(42, 52, 140)' }}>{children}</Link>
    :
    (<Link {...rest} style={{ display: inline ? "inline" : "initial" }}>
      <Typography color={color} variant={variant} style={{ ...typographyStyles, display: inline ? "inline" : "initial" }}>{children}</Typography>
    </Link>);
}

export default RGRLink;