exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-cloud-analytics-js": () => import("./../../../src/pages/cloud-analytics.js" /* webpackChunkName: "component---src-pages-cloud-analytics-js" */),
  "component---src-pages-data-tools-js": () => import("./../../../src/pages/data-tools.js" /* webpackChunkName: "component---src-pages-data-tools-js" */),
  "component---src-pages-data-visualization-js": () => import("./../../../src/pages/data-visualization.js" /* webpackChunkName: "component---src-pages-data-visualization-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-organization-payment-js": () => import("./../../../src/pages/organization-payment.js" /* webpackChunkName: "component---src-pages-organization-payment-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-premium-support-js": () => import("./../../../src/pages/premium-support.js" /* webpackChunkName: "component---src-pages-premium-support-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-and-terms-of-use-js": () => import("./../../../src/pages/privacy-policy-and-terms-of-use.js" /* webpackChunkName: "component---src-pages-privacy-policy-and-terms-of-use-js" */),
  "component---src-pages-purchase-premium-js": () => import("./../../../src/pages/purchase-premium.js" /* webpackChunkName: "component---src-pages-purchase-premium-js" */),
  "component---src-pages-register-faculty-js": () => import("./../../../src/pages/register-faculty.js" /* webpackChunkName: "component---src-pages-register-faculty-js" */),
  "component---src-pages-register-individual-js": () => import("./../../../src/pages/register-individual.js" /* webpackChunkName: "component---src-pages-register-individual-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-student-js": () => import("./../../../src/pages/register-student.js" /* webpackChunkName: "component---src-pages-register-student-js" */),
  "component---src-pages-register-trial-js": () => import("./../../../src/pages/register-trial.js" /* webpackChunkName: "component---src-pages-register-trial-js" */),
  "component---src-pages-release-notes-js": () => import("./../../../src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-request-training-js": () => import("./../../../src/pages/request-training.js" /* webpackChunkName: "component---src-pages-request-training-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-simulation-js": () => import("./../../../src/pages/simulation.js" /* webpackChunkName: "component---src-pages-simulation-js" */),
  "component---src-pages-statistical-analysis-js": () => import("./../../../src/pages/statistical-analysis.js" /* webpackChunkName: "component---src-pages-statistical-analysis-js" */),
  "component---src-pages-submit-testimonial-js": () => import("./../../../src/pages/submit-testimonial.js" /* webpackChunkName: "component---src-pages-submit-testimonial-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-video-tutorials-js": () => import("./../../../src/pages/video-tutorials.js" /* webpackChunkName: "component---src-pages-video-tutorials-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/BlogAuthor.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/BlogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tag-js": () => import("./../../../src/templates/BlogTag.js" /* webpackChunkName: "component---src-templates-blog-tag-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */)
}

