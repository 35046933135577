/**
 * * General TODO List
 * TODO: Implement PropTypes
 * TODO: LiveChat
 */

import React from 'react';
import Header from '../components/Header/Header.js';
import Footer from '../components/Footer/Footer';

import 'typeface-source-sans-pro';
import 'typeface-open-sans';

import cookie from 'react-cookies';
import axios from 'axios';

import Grid from '@mui/material/Grid';

import './index.css';
import './accessibility.css';
import 'katex/dist/katex.min.css';

import { StripeProvider } from 'react-stripe-elements';

import { stripePublicKey, apiURL } from '../Data';

import ContextConsumer, { ContextProviderComponent } from './context';

// Actual layout used in pages
// Cookies used to trigger accessibility related features
const MasterLayout = ({ children }) => (
  <Grid
    container
    direction='column'
    wrap='nowrap'
    className={
      (cookie.load('highContrast') === 'true' ? 'highContrast ' : '') +
      (cookie.load('largeText') === 'true' ? 'largeText' : '')
    }
  >
    <div id='skip-to-content-wrapper'>
      <a id='skip-to-content' href='#main-content' tabIndex='0' role='button'>
        Skip to content
      </a>
    </div>
    <Header />
    <main>{children}</main>
    <Footer />
  </Grid>
);

class ExportedMasterLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stripe: null };
  }

  // Stripe initialization
  componentDidMount = () => {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(stripePublicKey) });
    } else {
      console.log('Stripe failed to load');
    }

    axios.defaults.baseURL = apiURL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Accepts'] = 'application/json';
    axios.defaults.headers.get['Accepts'] = 'application/json';
  };
  /**
   * StripeProvider is for checkout process
   * ContextProviderComponent provides our own context
   * This context consumer pattern is found in any components' exports which use our own context
   */
  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <ContextProviderComponent>
          <ContextConsumer>
            {({ data, set }) => (
              <MasterLayout context={data} setContext={set} {...this.props} />
            )}
          </ContextConsumer>
        </ContextProviderComponent>
      </StripeProvider>
    );
  }
}

export default ExportedMasterLayout;
