import React from 'react';
import './Footer.css'

import Link from '../Link'

// import YTIcon from '../../images/youtube-icon.png'
// import LinkedInIcon from '../../images/linkedin-icon.png'
// import FBIcon from '../../images/facebook-icon.png'
import SoflyticsLogo from '../../images/soflytics_logo.png'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

/**
 * * Footer layout component
 * * Dumb component (No API, State, or Context)
 */

const Footer = (props) => {
  return (
    <footer>
      {/* <div id='unleash-the-power'>
        <Typography variant='h2' color='textSecondary'>
          Unleash the power of R on the cloud by simple point-and-clicks
        </Typography>
      </div> */}
      <Grid container justifyContent='space-between' id='main-footer'>
        <div id='footer-info'>
          <Typography className='light'>© {new Date().getFullYear()} Soflytics Corp. All Rights Reserved</Typography>
          <Typography className='light'>
            <Link to='/privacy-policy-and-terms-of-use/'>Privacy Policy & TOS</Link> |
            <Link to='/accessibility'>Accessibility</Link>
          </Typography>
          <Typography className='light'>Powered by Soflytics</Typography>
        </div>
        <Grid container direction='column' alignItems='center' justifyContent='center' style={{ width: 'auto' }} id='footer-images-wrapper'>
          {/* <div id='footer-icons'>
            <a href='https://www.youtube.com/channel/UCaY4gT66_NgYUqJRxvGI8QQ' target='_blank' rel="noopener noreferrer"><img src={YTIcon} alt='youtube icon'/></a>
            <a href='https://www.linkedin.com/company/rguroo/' target='_blank' rel="noopener noreferrer"><img src={LinkedInIcon} alt='linked in icon'/></a>
            <a href='https://www.facebook.com/Rguroo-380597549032129' target='_blank' rel="noopener noreferrer"><img src={FBIcon} alt='facebook icon'/></a>
          </div> */}
          <img src={SoflyticsLogo} alt='soflytics logo' />
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;