import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography'

import './Button.css'

/**
 * * Standard buttons used throughout the website
 * * API:
 * @param {Node} children Optional: Content of the button 
 * @param {Boolean} outlined Optional: Determines whether or not the button will have a border 
 * @param {String} typographyClasses Optional: Classes applied to Typography element 
 * @param {String} className Optional: Classes applied to ButtonBase component 
 * @param {CSS Class} animated Optional: Creates hover animation for button 
 * @param {CSS Class} large Optional: Increases font size and padding sice (if padded) 
 * @param {Boolean} noTypography Optional: Determines whether or not Typography will be used. TypographyClasses still optional but irrelevant
 *                               The point of this is in order to prevent nested <p> elements in case Button needs to be use in conjunction with Link 
 * @param {CSS Class} padded Optional: Determines whether or not the button will have a fair amount of padding 
 * @param {Hex String} backgroundColor Optional: Determines background color of button 
 * @param {Object} style Optional: Styles applied to ButtonBase 
 * @param rest... Applied to ButtonBase
 * * No state
 */

const Button = ({children, outlined, typographyClasses = '', className = '', noTypography = false, padded, backgroundColor, style = {}, ...rest}) => {
  const styles = {
    ...style,
    backgroundColor: outlined ? '#ffbc12':'transparent',
    border: outlined ? '1px solid rgb(102, 102, 102)':'none',
  }
  if(backgroundColor)
    styles.backgroundColor = backgroundColor;
  
  // Rendering without Typography component
  if(noTypography)
    return (
      <ButtonBase className={'rguroo-btn '+className} style={styles} tabIndex={rest.onClick?0:-1} {...rest} disableRipple>
        {children}
      </ButtonBase>
    );
  /** 
   * ! Note on tabIndex={rest.onClick?0:-1}. If there is an action associated with the button, it has to be accessible
   * ! Otherwise, there'll likely be a focusable element in children, hence the button itself does not need to be focusable
   */
  return (
    <ButtonBase className={'rguroo-btn '+className} style={styles} tabIndex={rest.onClick?0:-1} {...rest} disableRipple>
      <Typography className={typographyClasses} color='textSecondary'>{children}</Typography>
    </ButtonBase>
  );
}
 
export default Button;