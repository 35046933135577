import React from 'react';
import './RgurooList.css'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/**
 * * Dumb component used to display lists of items using the Rguroo color scheme
 * * API:
 * @param {Node} children Content of the list 
 */
const RgurooList = (props) => {
  return (
    <List className='rguroo-list'>
      {props.children}
    </List>
  );
}

export default RgurooList;

/**
 * * Component meant to be wrapped by RgurooList which displays sublists. Can nest multiple sublists
 * * API:
 * @param {Node} children Content of the list 
 * * State:
 * @param {Boolean} open Determines whether or not the sublist is open
 */
export class RgurooListSubmenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
    this.setOpen.bind(this);
  }
  setOpen = state => e => this.setState({ open:state }); 
  render() { 
    return (
      <ListItem className='rguroo-submenu-wrapper'>
        <Accordion expanded={this.state.open}>
          <AccordionSummary onClick={this.setOpen(!this.state.open)} className='rguroo-submenu-parent'>
            <Typography variant='button' color='textSecondary'>{this.props.title}</Typography>
            <KeyboardArrowDownIcon/>
          </AccordionSummary>
          <AccordionDetails>
            <List className='rguroo-list-submenu'>
              {this.props.children}
            </List>
          </AccordionDetails>
        </Accordion>
      </ListItem>
    )
  }
}
