import React from 'react';
import './Dropdown.css'

import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContextConsumer from '../../layouts/context'

/**
 * * Standard Dropdown Component to be used throughout the site
 * * API:
 * @param {String} uid Required: Unique ID used to determine which dropdown is open
 * @param {String} className Optional: Classes applied to .dropdown-parent
 * @param {String} color Optional: Color used for Typography components. Must be Typography compatible
 * @param {Boolean} disabled Optional: Determines whether or not the dropdown is disabled. Children are optional but irrelevant
 * @param {Node} children Optional: Content of the dropdown. Should be wrapped by ListItems in order for styles to be applied properly
 * @param {String} title Optional: Text which shows up as selected
 * @param {Boolean} clickable Optional: Will trigger events on click rather than on hover
 * * Context:
 * @param {String} openMenu Used in conjunction with @param uid to determine whether or not dropdown should be open
 */

let shouldClose = null;
class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
    }
  }

  closeMenu = e => {
    this.dontCloseMenu(null);
    this.setState({ complete: false });
    if(e !== null && e.type === 'blur') {
      // This is an Rguroo specific way to ensure that the last item on the dropdown triggers a close menu if tabbing through (accessibility)
      if(e.target === e.currentTarget || e.target === e.currentTarget.lastElementChild.lastElementChild.lastElementChild.lastElementChild.lastElementChild || e.target === e.currentTarget.lastElementChild.lastElementChild.lastElementChild.lastElementChild.lastElementChild.lastElementChild)
        this.props.setContext({openMenu: ''});
    } else {
      this.props.setContext({openMenu: ''});
    }
    if(e != null)
      e.stopPropagation();
  }

  maybeCloseMenu = e => {
    shouldClose = setTimeout(() => this.closeMenu(null), 100);
  }

  dontCloseMenu = e => {
    clearTimeout(shouldClose);
    shouldClose = null;
  }

  // This has a double effect. Since only one menu can be open at a time, when this menu opens any other menu will close
  openMenu = e => {
    this.dontCloseMenu(null);
    this.props.setContext({openMenu: this.props.uid})
    setTimeout(() => this.setState({ complete: true }), 350);
  };
  render() {
    const color = this.props.color ? this.props.color:'textSecondary'
    const className = this.props.className ? this.props.className:''
    if(this.props.disabled)
      return (
        <div className={'dropdown-parent disabled '+className}>
          <Typography color={color}>
            {this.props.title}
            <KeyboardArrowDownIcon className="dropdown-chevron"/>
          </Typography>
        </div>
      )
    if(!this.props.clickable)
      return (
        // eslint-disable-next-line
        <div className={`dropdown-parent ${className}`} role="presentation" tabIndex={0} onMouseEnter={this.openMenu} onMouseLeave={this.maybeCloseMenu} onFocus={this.openMenu} onBlur={this.closeMenu}>
          <Typography color={color}>
            {this.props.title}
            <KeyboardArrowDownIcon className="dropdown-chevron"/>
          </Typography>
          <Collapse in={this.props.context.openMenu === this.props.uid} className='dropdown-children-wrapper' onMouseLeave={this.closeMenu} onMouseEnter={this.dontCloseMenu} onKeyPress={this.closeMenu}>
            <List>
              {this.props.children}
            </List>
          </Collapse> 
        </div>
      );
    return (
        // eslint-disable-next-line
          <div className={`dropdown-parent ${className} ${this.state.complete}`} role="presentation" tabIndex={0} onBlur={this.closeMenu} onClick={this.openMenu} onFocus={this.openMenu}>
            <Typography  color={color}>
              {this.props.title}
              <KeyboardArrowDownIcon className="dropdown-chevron"/>
            </Typography>
            <Collapse in={this.props.context.openMenu === this.props.uid} className='dropdown-children-wrapper' onClick={this.closeMenu} onKeyPress={this.closeMenu}>
              <List>
                {this.props.children}
              </List>
            </Collapse> 
          </div>
    )
  }
}
 
export default (props) => <ContextConsumer>{({data, set}) => <Dropdown context={data} setContext={set} {...props}/>}</ContextConsumer>